import axios from '@axios'
import common from '@/libs/common'

export default function rolesService() {
  const { throwError } = common()
  const API_URL = process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchRoles = callback => {
    axios
      .get(`${API_URL}/roles`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createRole = (data, callback) => {
    axios
      .post(`${API_URL}/roles`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRole = ({ role }, callback) => {
    axios
      .get(`${API_URL}/roles/${role}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteRole = ({ role }, callback) => {
    axios
      .delete(`${API_URL}/roles/${role}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRole = ({ role }, data, callback) => {
    axios
      .put(`${API_URL}/roles/${role}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRoleUsers = ({ role }, callback) => {
    axios
      .get(`${API_URL}/roles/${role}/users`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteRoleUser = ({ role, userName }, callback) => {
    axios
      .delete(`${API_URL}/roles/${role}/${userName}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchRoles,
    createRole,
    fetchRole,
    deleteRole,
    updateRole,
    fetchRoleUsers,
    deleteRoleUser,
  }
}
