<template>
  <div>
    <bulletin-list-add
      :is-add-new-bulletin-sidebar-active.sync="isAddNewBulletinSidebarActive"
      @refresh-data="() => { refBulletinsList.refreshData() }"
    />
    <list
      ref="refBulletinsList"
      sort-by="BoletinId"
      key-field="BoletinId"
      refetch-records-name="boletines"
      controller-name="Boletines"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
    />
    <!-- <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Lists.Search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewBulletinSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Bulletin.newBulletin') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refBulletinsTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="refetchBulletins"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('Lists.NoMatchingRecordsFound')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-bulletin-details', params: { boletinId: data.item.boletinId } }"
            >
              <feather-icon icon="CodepenIcon" />
              <span class="align-middle ml-50">{{ $t('Bulletin.details') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBulletins"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div> -->
  </div>
</template>

<script>
// import {
//   BCard,
//   BRow,
//   BCol,
//   BFormInput,
//   BButton,
//   BPagination,
//   BTable,
//   BDropdown,
//   BDropdownItem,
// } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
// import bulletinService from '@/services/bulletin.service'
import i18n from '@/libs/i18n'

// import vSelect from 'vue-select'
import List from '@/components/List.vue'
import BulletinListAdd from './BulletinListAdd.vue'

export default {
  components: {
    // BCard,
    // BRow,
    // BCol,
    // BFormInput,
    // BButton,
    // BPagination,
    // BTable,
    // BDropdown,
    // BDropdownItem,

    // vSelect,
    List,
    BulletinListAdd,
  },
  setup() {
    /* Refs */
    const refBulletinsList = ref(null)
    /* UI */
    const isAddNewBulletinSidebarActive = ref(false)
    // const refBulletinsTable = ref(null)
    /* Services */
    // const {
    //   fetchBulletins,
    // } = bulletinService()
    // /* Pagination */
    // const perPageOptions = [10, 25, 50, 100]
    // const perPage = ref(10)
    // const currentPage = ref(1)
    // /* Params */
    // const searchQuery = ref('')
    // const sortBy = ref('BoletinId')
    // const isSortDirDesc = ref(false)

    // /* Config */
    // const totalBulletins = ref(0)
    // const bulletins = ref([])
    const actions = ref([
      {
        label: i18n.t('Bulletin.newBulletin'),
        aclAction: 'create',
        aclResource: 'Previos',
        click: () => { isAddNewBulletinSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const tableColumns = [
      {
        label: i18n.t('Bulletin.bulletinId'),
        key: 'boletinId',
        sortable: true,
        sortKey: 'BoletinId',
      },
      {
        label: i18n.t('Bulletin.bulletin'),
        key: 'titulo',
        sortable: true,
        sortKey: 'Titulo',
      },
      {
        label: i18n.t('Bulletin.typeBulletin'),
        key: 'boletinTipo.nombre',
        sortable: true,
        sortKey: 'BoletinTipo.Nombre',
      },
      {
        label: i18n.t('Bulletin.cancelled'),
        key: 'cancelado',
        sortable: true,
        sortKey: 'Cancelado',
      },
      {
        label: i18n.t('Bulletin.userCancelled'),
        key: 'usuarioCancelacion',
        sortable: true,
        sortKey: 'UsuarioCancelacion',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const filters = []
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'Boletines',
        routeName: 'apps-bulletin-details',
        params: ['boletinId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Cancel'),
        aclAction: 'delete',
        aclResource: 'Boletines',
        params: ['boletinId'],
        // disabledCondition: item => item.previoEstadoId === 3,
        icon: 'TrashIcon',
      },
    ])
    // const refetchBulletins = (ctx, callback) => {
    //   fetchBulletins({
    //     searchQuery: searchQuery.value,
    //     limit: perPage.value,
    //     offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
    //     sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
    //   }, data => {
    //     bulletins.value = data.boletines
    //     totalBulletins.value = data.total
    //     callback(data.boletines)
    //   })
    // }

    // const dataMeta = computed(() => {
    //   const localItemsCount = refBulletinsTable.value ? refBulletinsTable.value.localItems.length : 0
    //   return {
    //     from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
    //     to: perPage.value * (currentPage.value - 1) + localItemsCount,
    //     of: totalBulletins.value,
    //   }
    // })

    // const refreshData = () => {
    //   refBulletinsTable.value.refresh()
    // }
    // watch([currentPage, perPage, searchQuery], () => {
    //   refreshData()
    // })

    return {
      // Refs
      refBulletinsList,
      // refBulletinsTable,
      // Data
      // refreshData,
      // tableColumns,
      // totalBulletins,
      // refetchBulletins,
      // Pagination
      // perPageOptions,
      // perPage,
      // currentPage,
      // Params
      // searchQuery,
      // sortBy,
      // isSortDirDesc,
      // UI
      isAddNewBulletinSidebarActive,
      // dataMeta,
      // Config
      actions,
      tableColumns,
      filters,
      tableActions,
    }
  },
}
</script>

<style>

</style>
