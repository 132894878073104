<template>
  <b-sidebar
    id="add-new-bulletin-sidebar"
    :visible="isAddNewBulletinSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="80%"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-bulletin-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Bulletin.newBulletin') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Titile -->
          <validation-provider
            #default="validationContext"
            :name="$t('Bulletin.bulletin')"
            rules="required|max:150"
          >
            <b-form-group
              :label="$t('Bulletin.bulletin')"
              label-for="boletin"
            >
              <b-form-input
                id="nomboletinbre"
                v-model="bulletinData.titulo"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Request Type field -->
          <!-- <database-select
            v-model="bulletinData.boletinTipoId"
            domain-name="TiposBoletin"
            :label="$t('Bulletin.typeBulletin')"
            validation-rules="required"
          /> -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Bulletin.typeBulletin')"
            rules="required"
          >
            <b-form-group
              :label="$t('Bulletin.typeBulletin')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="bulletinData.boletinTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="bulletinTypes"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Content -->
          <!-- <div id="rich-text-content" /> -->
          <quill-editor
            v-model="bulletinData.contenido"
          />
          <!-- Files & subs-->
          <b-row
            class="mt-1"
          >
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('AppPart.fileAttachments')"
              >
                <file-uploader
                  v-model="bulletinData.archivoSeleccionado"
                  :file-name.sync="bulletinData.archivoSeleccionadoNombre"
                  @on-upload-file="onAddFile"
                />
              </b-form-group>
              <b-table
                :items="bulletinData.boletinAdjuntos"
                :fields="filesTableFields"
              >
                <template
                  #cell(actions)="data"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="onDeleteFile(data.item.guid)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              {{ $t('Bulletin.subscribers') }}
              <b-form-group
                :label="$t('Bulletin.role')"
                label-for="search-file-types"
                class="mt-1"
              >
                <v-select
                  v-model="bulletinData.roles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  label="name"
                  :options="roles"
                  :reduce="option => option.name"
                  :multiple="true"
                />
              </b-form-group>
              <b-form-group
                :label="$t('Users')"
                label-for="search-file-types"
              >
                <v-select
                  v-model="bulletinData.usuarios"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  label="name"
                  :options="users"
                  :reduce="option => option.userName"
                  :multiple="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-badge
            v-if="extraValidationMessage !== ''"
            pill
            variant="warning"
          >
            {{ extraValidationMessage }}
          </b-badge>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="addingBulletin"
            >
              <b-spinner
                v-if="addingBulletin"
                small
                class="mr-1"
              />
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="addingBulletin"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import i18n from '@/libs/i18n'
import formValidation from '@core/comp-functions/forms/form-validation'
import bulletinService from '@/services/bulletin.service'
import useradminService from '@/services/useradmin.service'
import rolesService from '@/services/roles.service'
import FileUploader from '@/components/FileUploader.vue'
// import { createEditor } from 'vueditor'
import optionsService from '@/services/option.service'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    FileUploader,
    BBadge,
    BSpinner,

    quillEditor,
  },
  props: {
    isAddNewBulletinSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Refs */
    // const refRichTextContent = ref(null)
    const extraValidationMessage = ref('')
    const addingBulletin = ref(false)
    // onMounted(() => {
    //   const richTextEditconfig = {
    //     // toolbar: [
    //     //   'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor',
    //     // ],
    //     fontName: [
    //       { val: 'arial black' },
    //       { val: 'times new roman' },
    //       { val: 'Courier New' },
    //     ],
    //     fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
    //     uploadUrl: '',
    //   }
    //   refRichTextContent.value = createEditor('#rich-text-content', richTextEditconfig)
    // })
    /* Services */
    const {
      createBulletin,
    } = bulletinService()
    const {
      fetchRoles,
    } = rolesService()
    const {
      fetchUsers,
    } = useradminService()
    const {
      fetchOptions,
    } = optionsService()
    /* Data */
    const blankBulletinData = {
      contenido: '',
      titulo: '',
      boletinAdjuntos: [],
      usuarios: [],
      roles: [],
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
    }

    const filesTableFields = [
      {
        key: 'archivo',
        label: i18n.t('Bulletin.labelArchive'),
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]

    const bulletinData = ref(JSON.parse(JSON.stringify(blankBulletinData)))
    const resetbulletinData = () => {
      bulletinData.value = JSON.parse(JSON.stringify(blankBulletinData))
    }

    const users = ref([])
    fetchUsers({}, data => {
      users.value = data.collection
    })

    const roles = ref([])
    fetchRoles(data => {
      roles.value = data
      roles.value.push({ name: '(Todos)' })
    })
    const bulletinTypes = ref([])
    fetchOptions({ nombreDominio: 'TiposBoletin' }, data => {
      bulletinTypes.value = data
    })
    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetbulletinData)
    /* Events */
    const onSubmit = () => {
      // bulletinData.value.contenido = refRichTextContent.value.getContent()

      if (bulletinData.value.contenido === '') {
        extraValidationMessage.value = 'Es necesario capturar contenido para el boletin'
        return
      }

      if (bulletinData.value.usuarios.length === 0 && bulletinData.value.roles.length === 0) {
        extraValidationMessage.value = 'Es necesario enviar el boletin a uno o varios usuarios y/o roles'
        return
      }

      addingBulletin.value = true
      createBulletin(bulletinData.value)
        .then(() => {
          emit('update:is-add-new-bulletin-sidebar-active', false)
          emit('refresh-data')
          resetbulletinData()
          extraValidationMessage.value = ''
          addingBulletin.value = false
        })
    }
    const onAddFile = () => {
      bulletinData.value.boletinAdjuntos.push(
        {
          guid: bulletinData.value.archivoSeleccionado,
          archivo: bulletinData.value.archivoSeleccionadoNombre,
        },
      )

      bulletinData.value.archivoSeleccionado = ''
      bulletinData.value.archivoSeleccionadoNombre = ''
    }
    const onDeleteFile = guid => {
      bulletinData.value.boletinAdjuntos = bulletinData.value.boletinAdjuntos.filter(f => f.guid !== guid)
    }

    return {
      // Data
      bulletinData,
      filesTableFields,
      users,
      roles,
      bulletinTypes,
      // Events
      onSubmit,
      onAddFile,
      onDeleteFile,

      // UI
      extraValidationMessage,
      addingBulletin,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
